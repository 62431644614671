<template>
  <div id="our">
    <div class="top"></div>
    <div class="main wrapper">
      <div class="title">关于我们</div>
      <div class="cnText">
        <p>
          <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp'"></span>
          武汉卫澜环保科技有限公司（以下简称：房东管家）于2019年初正式成立于洪山创业中心，以移动端“租房管理APP”为切入点，进入住房租赁市场，通过“收租管理”与“招租平台”相结合的方式，改变现有招租平台信息冗余虚假信息泛滥、房东被无节制骚扰、租房中介恶意竞争等乱象，最终完成对整个住房租赁市场的升级和改造。
        </p>
        <p>
          <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp'"></span>
          公司针对个人房东开发的第一款产品——房东管家，在“新”房东人群中可谓是尽人皆知。凭借出色的用户操作体验和良好的售后反馈机制，不断满足房东实际收租过程中遇到的各类管理需求，经过多年的持续迭代升级，已成为租房管理细分领域中的佼佼者。
        </p>
        <p>
          <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp'"></span>
          2017年，随着住房租赁市场的利好政策不断出台，越来越多的企业开始进军租房市场，于是公司凭借多年来开发和维护房东利器积累的经验，结合企业管理模式和审批流程，开发出适合大型出租物业管理企业使用的软件系统，也就是——房利聚企业版。满足企业用户多种管理角色的使用需求，最大限度地提升企业管理效率，降低企业用人成本，使企业更专注于核心业务，提高行业内的核心竞争力。
        </p>
        <p>
          <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp'"></span>
          至此，房东管家完成对出租方人群管理需求的全覆盖。
        </p>
        <p>
          <span v-html="'&nbsp;&nbsp;&nbsp;&nbsp'"></span>
          面向未来，房东管家以“推动住房租赁市场升级，打造一流租房平台”为己任，建立更好的更合理的租房市场新秩序，并接入保险、理财、贷款等金融产品，以及智能硬件、搬家、清洁等租房市场周边服务，打造“一站式”租房新体验，造福于人民。
        </p>
      </div>
      <img src="../../assets/images/our/banner.jpeg" alt="" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#our {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/our/我们背景.png);
    background-size: cover;
  }
  .main {
    .title {
      text-align: center;
      padding: 3% 0;
      font-size: 22px;
      font-weight: 700;
    }
    .cnText {
      letter-spacing: 1px;
      line-height: 35px;
      margin-bottom: 30px;
    }
  }
  img {
    width: 1200px;
    height: 300px;
    margin-bottom: 100px;
  }
}
</style>
